import {useHistory} from 'react-router-dom';
import {formatIban} from 'shared/utils.js';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function BankingAccountVibanReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listBankingAccountVibans"
      accessor={({viban}) => formatIban(viban)}
      action={(banking_account_viban) => {
        history.push(
          `${BACKOFFICE_PATHS.banking_account_vibans}/${banking_account_viban.id}`,
        );
      }}
    />
  );
}
