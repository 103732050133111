import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import DateInput from 'shared/components/DateInput';
import Input from 'shared/components/Input.js';
import Checkbox from 'shared/components/checkbox/Checkbox.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import IconButton from 'shared/components/IconButton.js';
import PhoneNumberInput from 'shared/components/phoneNumberInput/PhoneNumberInput.js';
import {
  gender_select_options,
  openExternal,
  toLocaleDate,
} from 'shared/utils.js';
import {useParams} from 'react-router-dom';
import {RPC} from 'shared/api.js';
import Select from 'shared/components/Select.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useNotify} from 'shared/NotifyProvider.js';

import {
  deleteManager as deleteManagerApi,
  updateManager,
} from '../../actions.js';
import {alert, confirm, handleError, confirmClose} from '../../effects.js';
import {ReactComponent as TrashSvg} from '../../assets/trash.svg';
import {ReactComponent as EditSvg} from '../../assets/edit.svg';
import {useResource} from '../../hooks.js';
import {DataGridField} from '../../components/data_grid_field/DataGridField.js';

import styles from './ManagerDialog.module.scss';

export default function ManagerDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {id} = useParams();
  const [manager] = useResource('listManagers', id);
  const {notify} = useNotify();

  const [readOnly, setReadOnly] = useState(true);
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
    reset,
  } = useForm({
    mode: 'onSubmit',
  });

  useEffect(() => {
    reset(manager);
  }, [reset, manager]);

  const onSubmit = handleSubmit((fields) => {
    return proceed({
      fields,
      manager,
      setError,
      setVisible,
      setReadOnly,
      notify,
    });
  });
  const type = watch('type');

  const Footer = (
    <div className={styles.footer}>
      {!readOnly && (
        <Button
          title="Save"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      )}
      {!readOnly && manager?.is_surrogate && (
        <Button
          title="Remove surrogate and onboard"
          onClick={() =>
            onboardSurrogateManager({
              manager,
              notify,
              setVisible,
              setReadOnly,
            })
          }
        />
      )}
      {readOnly &&
        (manager?.is_password_set ? (
          <Button
            title="Reset Password"
            onClick={() => resetManagerPassword({manager, notify})}
          />
        ) : (
          <Button
            title="Resend onboarding email"
            onClick={() => sendManagerOnboarding({manager, notify})}
          />
        ))}
      <Button
        title="Login as Manager"
        onClick={() => {
          openExternal(
            new URL(
              `impersonate-manager/${manager?.id}`,
              process.env.REACT_APP_API_URL,
            ),
          );
        }}
      />
    </div>
  );

  function TrashIcon() {
    return (
      <IconButton
        onClick={() =>
          deleteManager({manager, notify}).then((didDelete) => {
            if (didDelete) setVisible(false);
          })
        }>
        <TrashSvg />
      </IconButton>
    );
  }

  function EditIcon() {
    return (
      <IconButton onClick={() => setReadOnly(false)}>
        <EditSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Manager"
      show={visible}
      footer={Footer}
      additionalIcons={
        manager?.id && [readOnly && <EditIcon />, <TrashIcon key="delete" />]
      }
      onHide={() => confirmClose({isDirty})}
      {...props}>
      {manager?.id && <Input value={manager.id} label="ID" readOnly />}
      <Input
        label="First name"
        readOnly={readOnly}
        {...register('first_name')}
        error={errors.first_name?.message}
      />
      <Input
        label="Last name"
        readOnly={readOnly}
        {...register('last_name')}
        error={errors.last_name?.message}
      />
      <Input
        label="Type"
        readOnly
        {...register('type')}
        error={errors.type?.message}
      />
      <Input
        label="Company name"
        readOnly={readOnly}
        {...register('company_name')}
        error={errors.company_name?.message}
      />
      <Input
        label="Street name"
        readOnly={readOnly}
        {...register('street_name')}
        error={errors.street_name?.message}
      />
      <Input
        label="Street number"
        readOnly={readOnly}
        {...register('street_number')}
        error={errors.street_number?.message}
      />
      <Input
        label="Postal code"
        readOnly={readOnly}
        {...register('postal_code')}
        error={errors.postal_code?.message}
      />
      <Input
        label="Region"
        readOnly={readOnly}
        {...register('region')}
        error={errors.region?.message}
      />
      <Input
        label="Country"
        readOnly
        defaultValue={'DE'}
        {...register('country')}
        error={errors.country?.message}
      />
      <Input
        label="Email address"
        readOnly={readOnly}
        {...register('email_address')}
        error={errors.email_address?.message}
      />
      <Controller
        name="date_of_birth"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label="Date of Birth"
            readOnly={readOnly}
            maxDate={new Date()}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
      <Controller
        control={control}
        name="phone_number"
        render={({field: {value, onChange, name}}) => (
          <PhoneNumberInput
            value={value}
            onChange={onChange}
            label="Phone Number"
            readOnly={readOnly}
            error={errors[name]?.message}
          />
        )}
      />
      <Input
        label="Steuernummer (tax number)"
        readOnly={readOnly}
        {...register('tax_id_number')}
        error={errors.tax_id_number?.message}
      />
      <Input
        label="Swan user id"
        readOnly={true}
        {...register('swan_user_id')}
        error={errors.swan_user_id?.message}
      />
      {type === 'individual' && (
        <Select
          label="Gender"
          options={gender_select_options}
          error={errors.gender?.message}
          readOnly={readOnly}
          {...register('gender')}
        />
      )}
      {readOnly && (
        <DateInput
          value={manager?.created_at || null}
          label="Created at"
          readOnly
        />
      )}
      {readOnly && (
        <DateInput
          value={manager?.email_verified_at || null}
          label="Email verified at"
          readOnly
        />
      )}
      {readOnly && (
        <DateInput
          value={manager?.first_login_at || null}
          label="First login at"
          readOnly
        />
      )}
      <Controller
        control={control}
        name="enable_banking"
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            value={value}
            onChange={onChange}
            label="Enable Banking"
            disabled={readOnly}
            error={errors[name]?.message}
          />
        )}
      />

      {readOnly && (
        <DataGridField
          label="User Consents"
          value={manager?.user_consents || []}
          columns={user_consents_columns}
        />
      )}

      <Controller
        control={control}
        name="is_surrogate"
        render={({field: {value, onChange, name}}) => (
          <Checkbox
            id={name}
            value={value}
            onChange={onChange}
            label="Surrogate manager"
            disabled
            error={errors[name]?.message}
          />
        )}
      />
      <Controller
        name="company_founded_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label="Company Founded Date"
            readOnly={readOnly}
            maxDate={new Date()}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />
    </Dialog>
  );
}

async function proceed({
  fields,
  manager,
  setError,
  setVisible,
  setReadOnly,
  notify,
}) {
  if (fields.type === 'individual') delete fields.company_name;

  try {
    await updateManager({
      id: manager.id,
      ...paramsFromFields(fields),
    });
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'The changes have been saved.'});
  setVisible(false);
  setReadOnly(true);
}

function paramsFromFields({date_of_birth, ...fields}) {
  return {
    ...fields,
    date_of_birth: toLocaleDate(date_of_birth),
  };
}

async function deleteManager({manager, notify}) {
  const {first_name, last_name, id} = manager;

  if (
    !(await confirm({
      title: `Do you want to delete ${first_name} ${last_name}?`,
      text: 'This action is permanent',
      abort_label: 'Abort',
      proceed_label: 'Delete',
    }))
  ) {
    return;
  }

  try {
    await deleteManagerApi(id);
  } catch (err) {
    handleError(err);
    return;
  }

  notify({
    text: 'Manager was deleted.',
  });

  return true;
}

async function resetManagerPassword({manager, notify}) {
  try {
    await RPC('resetManagerPassword', {manager_id: manager.id});
  } catch (err) {
    handleError(err);
    return;
  }

  notify({
    text: 'Password reset email sent',
  });
}

async function sendManagerOnboarding({manager, notify}) {
  try {
    await RPC('scheduleTask', [
      'sendManagerOnboarding',
      {manager_id: manager.id},
    ]);
  } catch (err) {
    handleError(err);
    return;
  }

  notify({
    text: 'Manager onboarding email sent',
  });
}

async function onboardSurrogateManager({
  manager,
  notify,
  setVisible,
  setReadOnly,
}) {
  try {
    await RPC('onboardSurrogateManager', {manager_id: manager.id});
  } catch (err) {
    handleError(err);
    return;
  }

  notify({
    text: 'Manager onboarding email sent',
  });
  setVisible(false);
  setReadOnly(true);
}

const user_consents_columns = [
  {
    id: 'description',
    Header: 'Description',
    accessor: 'description',
    text_styles: 'body2',
  },
  {
    id: 'version',
    Header: 'Version',
    accessor: 'version',
    text_styles: 'body2',
  },
];
