import {formatDate, formatIban} from 'shared/utils.js';
import {useSelector} from 'react-redux';
import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import RentalContractReferenceField from '../../components/reference_field/RentalContractReferenceField.js';
import BankingAccountReferenceField from '../../components/reference_field/BankingAccountReferenceField.js';

import BankingAccountVibanDialog from './BankingAccountVibanDialog.js';
import styles from './BankingAccountVibansScreen.module.scss';

export default function BankingAccountVibansScreen() {
  const {refresh} = useSelector((state) => state.banking_account_vibans);

  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listBankingAccountVibans', refresh});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.banking_account_vibans}/:id`}>
        <BankingAccountVibanDialog
          onExited={() => history.push(BACKOFFICE_PATHS.banking_account_vibans)}
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(banking_account_viban) =>
            history.push(
              `${BACKOFFICE_PATHS.banking_account_vibans}/${banking_account_viban.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    text_styles: 'body2',
    sort: 'id',
  },
  {
    id: 'viban',
    Header: 'Viban',
    accessor: 'viban',
    Cell: ({value}) => (value ? formatIban(value) : ''),
    text_styles: 'body2',
    sort: 'viban',
  },
  {
    id: 'banking_account_id',
    Header: 'Banking account',
    accessor: 'banking_account_id',
    Cell: ({value}) => <BankingAccountReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'banking_account_id',
  },
  {
    id: 'rental_contract_id',
    Header: 'Rental contract',
    accessor: 'rental_contract_id',
    Cell: ({value}) => <RentalContractReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'rental_contract_id',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => (value ? formatDate(value) : ''),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
