import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import SearchInput from '../../components/search_input/SearchInput.js';

import SwanEventDialog from './SwanEventDialog.js';
import styles from './SwanEventsScreen.module.scss';

export default function SwanEventsScreen() {
  const {
    orderBy,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
    setSearchQuery,
  } = useFetchTableData({method: 'listSwanEvents'});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.swan_events}/:id`}>
        <SwanEventDialog
          onExited={() => history.push(BACKOFFICE_PATHS.swan_events)}
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(swan_event) =>
            history.push(`${BACKOFFICE_PATHS.swan_events}/${swan_event.id}`)
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'id',
    Header: 'Id',
    accessor: 'id',
    text_styles: 'body2',
    sort: 'id',
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({value}) => new Date(value).toLocaleString(),
    text_styles: 'body2',
    sort: 'created_at',
  },
  {
    id: 'eventId',
    Header: 'eventId',
    accessor: 'event.eventId',
    text_styles: 'body2',
  },
  {
    id: 'eventDate',
    Header: 'eventDate',
    accessor: 'event.eventDate',
    text_styles: 'body2',
  },
  {
    id: 'eventType',
    Header: 'eventType',
    accessor: 'event.eventType',
    text_styles: 'body2',
  },
  {
    id: 'projectId',
    Header: 'projectId',
    accessor: 'event.projectId',
    text_styles: 'body2',
  },
  {
    id: 'resourceId',
    Header: 'resourceId',
    accessor: 'event.resourceId',
    text_styles: 'body2',
  },
];
