import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function SwanEventReferenceField({id, ...props}) {
  const history = useHistory();

  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listSwanEvents"
      accessor="id"
      action={(swan_event) => {
        history.push(`${BACKOFFICE_PATHS.swan_events}/${swan_event.id}`);
      }}
    />
  );
}
