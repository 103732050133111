import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import BankingAccountReferenceField from '../../components/reference_field/BankingAccountReferenceField.js';
import SwanEventReferenceField from '../../components/reference_field/SwanEventReferenceField.js';

import styles from './SwanRequestsScreen.module.scss';
import SwanRequestDialog from './SwanRequestDialog.js';

export default function SwanRequestsScreen() {
  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listSwanRequests'});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.swan_requests}/:id`}>
        <SwanRequestDialog
          onExited={() => history.push(BACKOFFICE_PATHS.swan_requests)}
        />
      </Route>
      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(swan_request) =>
            history.push(`${BACKOFFICE_PATHS.swan_requests}/${swan_request.id}`)
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'id',
    Header: 'Id',
    accessor: 'id',
    text_styles: 'body2',
    sort: 'id',
  },
  {
    id: 'error',
    Header: 'Error',
    accessor: 'error',
    text_styles: 'body2',
    sort: 'error',
  },
  {
    id: 'swan_event_id',
    Header: 'Swan Event',
    accessor: 'swan_event_id',
    Cell: ({value}) => (value ? <SwanEventReferenceField id={value} /> : ''),
    text_styles: 'body2',
    sort: 'swan_event_id',
  },
  {
    id: 'banking_account_id',
    Header: 'Banking Account',
    accessor: 'banking_account_id',
    Cell: ({value}) =>
      value ? <BankingAccountReferenceField id={value} /> : '',
    text_styles: 'body2',
    sort: 'banking_account_id',
  },
  {
    id: 'created_at',
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({value}) => (value ? new Date(value).toLocaleString() : ''),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
