import Input from 'shared/components/Input.js';
import {formatDate, formatIban} from 'shared/utils.js';
import SeparatorLine from 'shared/components/SeparatorLine.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import ManagerReferenceField from '../../components/reference_field/ManagerReferenceField.js';
import {DataGridField} from '../../components/data_grid_field/DataGridField.js';
import {useResource} from '../../hooks.js';

import styles from './PropertyOwnerDialog.module.scss';

export default function PropertyOwnerDialog({...props}) {
  const {id} = useParams();
  const [property_owner] = useResource('listPropertyOwners', id);

  return (
    <Dialog title="Property Owner" {...props}>
      <div className={styles.two_columns}>
        <Input
          value={
            property_owner.first_name
              ? `${property_owner.first_name} ${property_owner.last_name}`
              : ''
          }
          label="Name"
          readOnly
        />
        <Input
          value={property_owner?.company_name || ''}
          label="Company name"
          readOnly
        />

        <Input
          value={property_owner?.phone_number || ''}
          label="Phone number"
          readOnly
        />
        <Input
          value={property_owner?.email_address || ''}
          label="Email address"
          readOnly
        />

        <ManagerReferenceField
          id={property_owner?.manager_id}
          label="Manager"
        />
        <Input
          value={
            property_owner?.created_at
              ? formatDate(property_owner.created_at)
              : ''
          }
          label="Created at"
          readOnly
        />
      </div>

      <SeparatorLine />

      <div className={styles.two_columns}>
        <Input
          value={property_owner?.street_name || ''}
          label="Street name"
          readOnly
        />
        <Input
          value={property_owner?.street_number || ''}
          label="Street number"
          readOnly
        />

        <Input
          value={property_owner?.postal_code || ''}
          label="Postal code"
          readOnly
        />
        <Input value={property_owner?.region || ''} label="Region" readOnly />

        {property_owner.type === 'individual' && (
          <Input value={property_owner?.gender || ''} label="Gender" readOnly />
        )}
      </div>

      {property_owner.banking_accounts?.length > 0 && (
        <>
          <SeparatorLine />

          <DataGridField
            label="Banking accounts"
            value={property_owner.banking_accounts}
            columns={banking_accounts_columns}
          />
        </>
      )}

      {property_owner.has_coowner && (
        <>
          <SeparatorLine />

          <div className={styles.two_columns}>
            <Input
              value={property_owner.coowner_first_name || ''}
              label="Co-owner first name"
              readOnly
            />
            <Input
              value={property_owner.coowner_last_name || ''}
              label="Co-owner last name"
              readOnly
            />
            <Input
              value={property_owner?.coowner_gender || ''}
              label="Co-owner gender"
              readOnly
            />
          </div>
        </>
      )}
    </Dialog>
  );
}

const banking_accounts_columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    text_styles: 'body2',
  },
  {
    id: 'iban',
    Header: 'IBAN',
    accessor: 'iban',
    Cell: ({value}) => (value ? formatIban(value) : '-'),
    text_styles: 'body2',
  },
];
