import {useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import ReferenceField from './ReferenceField.js';

export default function BankingAccountReferenceField({id, ...props}) {
  const history = useHistory();
  return (
    <ReferenceField
      {...props}
      id={id}
      rpcListMethod="listBankingAccounts"
      accessor={({id}) => id}
      action={(banking_account) => {
        history.push(
          `${BACKOFFICE_PATHS.banking_accounts}/${banking_account.id}`,
        );
      }}
    />
  );
}
