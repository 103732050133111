import React from 'react';
import Input from 'shared/components/Input.js';
import {formatDate} from 'shared/utils';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import PropertyOwnerReferenceField from '../../components/reference_field/PropertyOwnerReferenceField.js';
import {DataGridField} from '../../components/data_grid_field/DataGridField.js';
import RentalContractReferenceField from '../../components/reference_field/RentalContractReferenceField.js';
import BankingAccountVibanReferenceField from '../../components/reference_field/BankingAccountVibanReferenceField.js';
import {useResource} from '../../hooks.js';

export default function BankingAccountDialog({...props}) {
  const {id} = useParams();
  const [banking_account] = useResource('listBankingAccounts', id);

  return (
    <Dialog title="Banking Account" {...props}>
      <div>
        <Input value={id} label="ID" readOnly />
        <Input
          value={banking_account?.swan_onboarding_url || ''}
          label="Swan onboarding url"
          readOnly
        />
        <Input
          value={banking_account.swan_onboarding_id || ''}
          label="Swan onboarding ID"
          readOnly
        />
        <Input
          value={banking_account.swan_account_id || ''}
          label="Swan Account ID"
          readOnly
        />
        <Input value={banking_account.iban || ''} label="IBAN" readOnly />
        <Input value={banking_account.bic || ''} label="BIC" readOnly />
        <Input
          value={banking_account.description || ''}
          label="Description"
          readOnly
        />
        <PropertyOwnerReferenceField
          id={banking_account.property_owner_id || ''}
          label="Property owner"
        />
        <Input
          value={formatDate(banking_account.created_at)}
          label="Created at"
          readOnly
        />

        {banking_account.vibans?.length > 0 && (
          <DataGridField
            label="Vibans"
            value={banking_account.vibans}
            columns={viban_columns}
          />
        )}
      </div>
    </Dialog>
  );
}

const viban_columns = [
  {
    id: 'viban',
    Header: 'VIBAN',
    accessor: 'id',
    Cell: ({value}) =>
      value ? <BankingAccountVibanReferenceField id={value} /> : '',
    text_styles: 'body2',
  },
  {
    id: 'rental_contract_id',
    Header: 'Rental contract',
    accessor: 'rental_contract_id',
    Cell: ({value}) =>
      value ? <RentalContractReferenceField id={value} /> : '',
    text_styles: 'body2',
  },
];
