import {useForm, Controller} from 'react-hook-form';
import Input from 'shared/components/Input.js';
import Button from 'shared/components/Button.js';
import {setFormErrors} from 'shared/effects.js';
import Dialog from 'shared/components/dialog/Dialog.js';
import {useState} from 'react';
import {useNotify} from 'shared/NotifyProvider.js';
import Select from 'shared/components/Select.js';
import {bank_select_options} from 'shared/constants.js';

import {alert, handleError, confirmClose} from '../../effects.js';
import {createDepositAccount} from '../../actions.js';
import SelectManager from '../../components/SelectManager.js';

export default function CreateDepositAccountDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const {notify} = useNotify();
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onChange',
  });
  const onSubmit = handleSubmit((fields) =>
    proceed({fields, setError, setVisible, notify}),
  );

  const Footer = (
    <Button
      title="Save"
      loading={isSubmitting}
      onClick={onSubmit}
      disabled={!isDirty}
    />
  );

  return (
    <Dialog
      show={visible}
      title="Create Deposit Account"
      footer={Footer}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <Controller
        control={control}
        name="manager_id"
        render={({field: {name, value, onChange}}) => (
          <SelectManager
            label="Manager"
            name={name}
            value={value}
            onChange={onChange}
            readOnly={false}
            error={errors.manager_id?.message}
            rpc_method="listNonSurrogateManagers"
          />
        )}
      />
      <Input label="IBAN" {...register('iban')} error={errors.iban?.message} />
      <Input label="BIC" {...register('bic')} error={errors.bic?.message} />
      <Select
        label="Bank"
        options={bank_select_options}
        error={errors.bank?.message}
        {...register('bank')}
      />
    </Dialog>
  );
}

async function proceed({fields, setError, setVisible, notify}) {
  try {
    await createDepositAccount(fields);
  } catch (err) {
    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    } else {
      handleError(err);
    }
    return;
  }
  notify({text: 'Deposit account was created.'});
  setVisible(false);
}
