import Input from 'shared/components/Input.js';
import {formatDate, formatIban} from 'shared/utils.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import RentalContractReferenceField from '../../components/reference_field/RentalContractReferenceField.js';
import BankingAccountReferenceField from '../../components/reference_field/BankingAccountReferenceField.js';
import {useResource} from '../../hooks.js';

export default function BankingAccountVibanDialog({...props}) {
  const {id} = useParams();
  const [banking_account_viban] = useResource('listBankingAccountVibans', id);

  return (
    <Dialog title="Banking Account Viban" {...props}>
      <Input value={banking_account_viban?.id || ''} label="ID" readOnly />
      <Input
        value={
          banking_account_viban?.viban
            ? formatIban(banking_account_viban.viban)
            : ''
        }
        label="Viban"
        readOnly
      />
      <BankingAccountReferenceField
        id={banking_account_viban.banking_account_id || ''}
        label="Banking account"
      />
      <RentalContractReferenceField
        id={banking_account_viban?.rental_contract_id}
        label="Rental contract"
      />
      <Input
        value={
          banking_account_viban?.created_at
            ? formatDate(banking_account_viban.created_at)
            : ''
        }
        label="Created at"
        readOnly
      />
    </Dialog>
  );
}
