import Input from 'shared/components/Input.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import BankingAccountReferenceField from '../../components/reference_field/BankingAccountReferenceField.js';
import SwanEventReferenceField from '../../components/reference_field/SwanEventReferenceField.js';
import {JsonField} from '../../components/JsonField.js';
import {useResource} from '../../hooks.js';

export default function SwanRequestDialog({...props}) {
  const {id} = useParams();
  const [swan_request] = useResource('listSwanRequests', id);

  const {
    created_at,
    error,
    request,
    response,
    swan_event_id,
    banking_account_id,
    duration_ms,
  } = swan_request;

  return (
    <Dialog title="Swan Request" {...props}>
      <Input value={id} label="Id" readOnly />
      <Input
        value={new Date(created_at).toLocaleString()}
        label="Created at"
        readOnly
      />
      <Input value={error} label="Error" readOnly />
      <SwanEventReferenceField id={swan_event_id} label="Swan event" />
      <BankingAccountReferenceField
        id={banking_account_id}
        label="Banking account"
      />
      <Input
        value={duration_ms ? duration_ms / 1000 : null}
        label="Duration in seconds"
        readOnly
      />
      <JsonField value={request} name="request" label="Request" />
      <JsonField value={response} name="response" label="Response" />
    </Dialog>
  );
}
