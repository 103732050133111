import {formatDate, formatIban} from 'shared/utils.js';
import {useSelector} from 'react-redux';
import {Route, useHistory} from 'react-router-dom';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import Pagination from '../../components/Pagination.js';
import Layout from '../../components/layout/Layout.js';
import SearchInput from '../../components/search_input/SearchInput.js';
import Table from '../../components/Table.js';
import {useFetchTableData} from '../../hooks.js';
import PropertyOwnerReferenceField from '../../components/reference_field/PropertyOwnerReferenceField.js';

import BankingAccountDialog from './BankingAccountDialog.js';
import styles from './BankingAccountsScreen.module.scss';

export default function BankingAccountsScreen() {
  const {refresh} = useSelector((state) => state.banking_accounts);

  const {
    orderBy,
    setSearchQuery,
    sort_column,
    sort_direction,
    entries_per_page,
    loading,
    rows,
    total,
    setOffset,
    offset,
  } = useFetchTableData({method: 'listBankingAccounts', refresh});

  const history = useHistory();

  return (
    <Layout search={<SearchInput onValue={setSearchQuery} />}>
      <Route path={`${BACKOFFICE_PATHS.banking_accounts}/:id`}>
        <BankingAccountDialog
          onExited={() => history.push(BACKOFFICE_PATHS.banking_accounts)}
        />
      </Route>

      <div className={styles.content}>
        <Table
          data={rows}
          onOpen={(banking_account) =>
            history.push(
              `${BACKOFFICE_PATHS.banking_accounts}/${banking_account.id}`,
            )
          }
          orderBy={orderBy}
          sort_column={sort_column}
          sort_direction={sort_direction}
          loading={loading}
          columns={columns}
        />
        {!!total && (
          <Pagination
            total={total}
            offset={offset}
            setOffset={setOffset}
            per_page={entries_per_page}
          />
        )}
      </div>
    </Layout>
  );
}

const columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    text_styles: 'body2',
    sort: 'id',
  },
  {
    id: 'iban',
    Header: 'IBAN',
    accessor: ({iban}) => formatIban(iban),
    text_styles: 'body2',
    sort: 'iban',
  },
  {
    id: 'bic',
    Header: 'BIC',
    accessor: 'bic',
    text_styles: 'body2',
    sort: 'bic',
  },
  {
    id: 'property_owner_id',
    Header: 'Property owner',
    accessor: 'property_owner_id',
    Cell: ({value}) => <PropertyOwnerReferenceField id={value} />,
    text_styles: 'body2',
    sort: 'property_owner_id',
  },
  {
    id: 'created_at',
    Header: 'Created at',
    accessor: 'created_at',
    Cell: ({value}) => formatDate(value),
    text_styles: 'body2',
    sort: 'created_at',
  },
];
