import React, {useRef} from 'react';
import Button from 'shared/components/Button.js';
import classNames from 'classnames';
import {useNotify} from 'shared/NotifyProvider.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import ReactJson from 'react-json-view';

import {alert} from '../../effects.js';

import styles from './UploadBulkImportCsvButton.module.scss';

export default function UploadBulkImportCsvButton() {
  const fileInputRef = useRef(null);
  const {notify} = useNotify();

  return (
    <>
      <input
        type="file"
        style={{display: 'none'}}
        ref={fileInputRef}
        accept="text/csv"
        onChange={(ev) => handleFileUpload(ev, {notify})}
      />
      <Button
        title="Upload Bulk Import CSV"
        onClick={() => {
          fileInputRef.current.click();
        }}
      />
    </>
  );
}

async function handleFileUpload(ev, {notify}) {
  const bulk_import_csv_file = ev.target.files[0];
  if (!bulk_import_csv_file) return;

  const bulk_import_csv = await bulk_import_csv_file.text();

  try {
    await RPC('cashDepositBulkImport', {
      bulk_import_csv,
      filename: bulk_import_csv_file.name,
    });
    notify({text: 'Bulk Import CSV successfully uploaded'});
  } catch (err) {
    if (err.code === BAD_REQUEST && err.data) {
      alert({
        is_wide_modal: true,
        hide_image: true,
        title: 'Validation error',
        text: (
          <>
            <p className={classNames(text_styles.body2_centered, styles.text)}>
              {`The file "${bulk_import_csv_file.name}" contains invalid data.`}
            </p>
            <p className={classNames(text_styles.body2_centered, styles.text)}>
              The validation errors in the following are displayed in their raw
              format. The paths start with <code>/&lt;row_index&gt;</code>, in
              the messages they are being referenced with
              <code>[&lt;row_index&gt;].&lt;column_name&gt;</code>. If you take
              the <code>&lt;row_index&gt;</code> and add 2 to that, you get the
              number of the row in excel (or the CSV line number) as a result.
            </p>
            <p className={classNames(text_styles.body2_centered, styles.text)}>
              Please correct the errors and proceed to attempt a new upload.
            </p>
            <div className={styles.error_details}>
              <ReactJson
                collapsed={2}
                displayObjectSize={false}
                displayDataTypes={false}
                src={err.data}
              />
            </div>
          </>
        ),
      });
    } else {
      notify({text: err.message || err.code.toString()});
    }
  } finally {
    ev.target.value = null;
  }
}
