import Input from 'shared/components/Input.js';
import {useParams} from 'react-router-dom';
import Dialog from 'shared/components/dialog/Dialog.js';

import {JsonField} from '../../components/JsonField.js';
import {useResource} from '../../hooks.js';

import styles from './SwanEventDialog.module.scss';

export default function SwanEventDialog({...props}) {
  const {id} = useParams();
  const [swan_event] = useResource('listSwanEvents', id);

  return (
    <Dialog title="Swan Event" className={styles.dialog} {...props}>
      <Input value={swan_event.id} label="Id" readOnly />

      <Input
        value={new Date(swan_event.created_at).toLocaleString()}
        label="Created at"
        readOnly
      />
      <JsonField
        collapsed={false}
        value={swan_event.event}
        name="event"
        label="Event"
      />
    </Dialog>
  );
}
